:root {
    --aconto-header-height-mobile: 58px;
    --aconto-header-height-desktop: 58px;
    --aconto-footer-height: 60px;

    --aconto-public-gray: #f4f4f4;
    --aconto-public-text: #242e38;
}

@font-face {
    font-family: "Roboto";
    src: url("../../fonts/Roboto.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Black";
    src: url("../../fonts/Roboto-Black.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-BlackItalic";
    src: url("../../fonts/Roboto-BlackItalic.ttf");
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0px;
    font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Roboto-Black;
}

#public-layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

#public-layout main {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: inherit;
    height: calc(100vh - var(--aconto-header-height-desktop) - var(--aconto-footer-height));
    margin-top: var(--aconto-header-height-desktop);
}
