.menu-item,
#language-select {
    display: flex;
    flex-flow: row nowrap;
    max-width: fit-content;
    align-items: center;
    margin: 0;
    cursor: pointer;
}
.menu-item > #language-select .pi .pi-globe {
    max-width: fit-content;
    color: white !important;
    padding: 0.75rem 1rem;
}
.menu-item #language-select-dropdown {
    border: none;
    background-color: transparent;
    box-shadow: none;
    display: flex;
    flex-flow: row nowrap;
    width: 0px;
    align-items: stretch;
}
.menu-item > #language-select-dropdown span.p-inputtext {
    box-shadow: none;
}
.menu-item > #language-select-dropdown .p-dropdown-label.p-inputtext.p-placeholder {
    padding: 0;
    margin: 0;
}
.menu-item > #language-select-dropdown .p-dropdown-label.p-inputtext.p-dropdown-label-empty {
    padding: 0;
    margin: 0;
}
.menu-item > #language-select-dropdown .p-dropdown-trigger {
    max-width: fit-content;
    color: white;
    padding: 8px 0;
}
.language-select-dropdown-panel {
    background: var(--aconto-header-background) !important;
    color: white !important;
    top: 60px !important;
}
.language-select-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background: var(--aconto-header-background-highlight) !important;
    color: white !important;
}
.language-select-dropdown-panel .p-dropdown-items .p-dropdown-item {
    color: white !important;
}
.locale-item .locale-flag {
    width: 35px !important;
}
.menu-item .select-flag {
    margin: 0px 5px !important;
}

#language-select  i {
    margin-right: 0;
}
