:root {
    --aconto-skew-degrees: 4deg;
}

#public-layout .section {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    padding: 20px 5% 20px 10%;
    align-items: flex-start;
    box-sizing: border-box;
}

#public-layout .section.do-more {
    padding-bottom: 0;
    margin-bottom: -2px;
}

#public-layout .section .content-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#public-layout .section .section-content {
    width: 40%;
    margin: 80px 0;
}

#public-layout .section.light::after {
    content: "";
    display: inline-block;
    position: absolute;
    background-color: white;
}

#public-layout .section.dark::after {
    content: "";
    display: inline-block;
    position: absolute;
    background-color: #f4f4f4;
}

#public-layout .section .features-line {
    position: absolute;
    inset: 0;
    top: 50% !important;
    z-index: -1000;
    background-color: #f4f4f4;
}

#public-layout .up::after {
    inset: 0;
    z-index: -1000;
    -webkit-transform: skewY(calc(-1 * var(--aconto-skew-degrees)));
    -moz-transform: skewY(calc(-1 * var(--aconto-skew-degrees)));
    -ms-transform: skewY(calc(-1 * var(--aconto-skew-degrees)));
    -o-transform: skewY(calc(-1 * var(--aconto-skew-degrees)));
    transform: skewY(calc(-1 * var(--aconto-skew-degrees)));
}

#public-layout .down::after,
#public-layout .features-line.down {
    inset: 0;
    z-index: -1000;
    -webkit-transform: skewY(var(--aconto-skew-degrees));
    -moz-transform: skewY(var(--aconto-skew-degrees));
    -ms-transform: skewY(var(--aconto-skew-degrees));
    -o-transform: skewY(var(--aconto-skew-degrees));
    transform: skewY(var(--aconto-skew-degrees));
}

#public-layout .intro::after {
    margin-top: -500px;
}

#public-layout .do-more::after {
    bottom: 50%;
}

#public-layout hr {
    border: none;
    border-bottom: 2px solid var(--aconto-public-text);
    width: 200px;
    margin: 20px 0 !important;
}

#public-layout .section h1 {
    font-size: 64px;
}

#public-layout .section p {
    text-align: justify;
}

#public-layout .section .section-images {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: center;
}

#public-layout .section .section-images.mobile {
    display: none;
}

#public-layout .why-aconto.section .section-images {
    padding-right: 40px;
}

#public-layout .do-more.section .section-images {
    align-items: flex-end;
    margin-bottom: -3px;
}

#public-layout .section .section-images > img,
#public-layout .section .section-images > svg {
    max-width: 700px;
    width: 100%;
    max-height: 100%;
    object-fit: contain;
}

/* Custom button colors and variations (hover, focus) */
#public-layout .do-more.section button.p-button {
    background-color: var(--landing-do-more);
    border: 1px solid var(--landing-do-more);
}
#public-layout .do-more.section button.p-button:hover {
    background-color: var(--landing-do-more-highlight);
    border: 1px solid var(--landing-do-more-highlight);
}
#public-layout .do-more.section button.p-button:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--landing-do-more-highlight), 0 1px 2px 0;
}

@media screen and (max-width: 1200px) {
    #public-layout .section h1 {
        font-size: 48px;
    }
}

@media screen and (max-width: 1000px) {
    #public-layout .section h1 {
        font-size: 42px;
    }
}

@media screen and (max-width: 960px) {
    #public-layout .p-menubar-start span {
        display: none;
    }

    #public-layout .paperwork.section .section-images {
        width: 55%;
    }
}

@media screen and (max-width: 840px) {
    #public-layout .section {
        padding: 32px 16px;
    }

    #public-layout .section .section-content {
        margin: 20px 0;
    }

    #public-layout .section .content-wrapper {
        flex-direction: column;
        align-items: center;
    }

    #public-layout .section .section-images.desktop {
        display: none;
    }
    #public-layout .section .section-images.mobile {
        display: unset;
        width: 90%;
    }

    #public-layout .section .section-content,
    #public-layout .section .section-images {
        width: 100%;
    }

    #public-layout .section .section-images {
        max-width: 500px;
        padding: unset !important;
    }

    #public-layout .paperwork.section .section-images {
        height: unset;
        max-width: 100%;
    }

    #public-layout .why-aconto.section .section-images > img {
        max-width: 100%;
        max-height: 100%;
    }

    #public-layout .paperwork.section .section-images > div {
        display: flex;
        width: 100%;
        align-items: center;
        position: unset;
        margin: 16px 0;
    }

    #public-layout .paperwork.section .section-images > div img {
        width: 40px;
        max-height: 48px;
        margin-right: 20px;
    }

    #public-layout .paperwork.section .section-images > div > div {
        flex-grow: unset;
        width: calc(100% - 60px); /* 40px image width + 20px margin */
    }

    #public-layout .why-aconto.section .content-wrapper,
    #public-layout .do-more.section .content-wrapper {
        flex-direction: column-reverse;
    }
}
