#public-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--aconto-footer-height);
    background-color: #242e38;
    color: white;
}

#public-footer span {
    margin: 0 16px;
}

@media screen and (max-width: 960px) {
    #public-footer {
        font-size: smaller;
    }

    #public-footer span {
        margin: 0 8px;
    }
}
