.external-vacation-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: inherit;
    background-color: var(--aconto-background-gray);
    padding: 50px;
    min-height: 100vh;
}

.external-vacation-container .vacation-content {
    background-color: white;
    border: 1px solid #d4d4d8;
    border-radius: 0.375rem;
    padding: 50px;
    position: relative;
}

.external-vacation-container .vacation-content h1 {
    margin-top: 0;
    text-align: center;
}

.external-vacation-container .vacation-content .work-attendances-container {
    margin-top: 20px;
}

.external-vacation-container .vacation-content .p-datepicker {
    border: none;
}

.external-vacation-container .vacation-content textarea {
    width: 100%;
}

.external-vacation-container .vacation-content .actions {
    margin-top: 10px;
}

.external-vacation-container .vacation-content .actions button {
    margin-right: 20px;
}

.external-vacation-container .vacation-content .request-status {
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 0;
}

.external-vacation-container .error-msg-container {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.external-vacation-container i.icon {
    color: var(--info-color);
    font-size: 2em;
}

.external-vacation-container #language-select {
    position: absolute;
    top: 1rem;
    right: 1rem;
}