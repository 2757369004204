header.public-header {
    width: 100%;
    position: fixed;
    z-index: 2000;
    background-color: white;
    height: var(--aconto-header-height-desktop);
    display: flex;
    justify-content: space-between;
    background: #fafafa;
    color: #3f3f46;
    border-radius: 0.375rem;
}

header.public-header .p-menubar-start {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}

header.public-header .p-menubar-start span {
    font-size: 32px;
    font-family: "Roboto-Black";
}

header.public-header .public-navigation {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    justify-content: center;
    background: unset;
    border: unset;
    border-radius: unset;
}

header.public-header .public-nav-logo {
    max-width: 100px;
    max-height: 100%;
    object-fit: contain;
    padding: 20px;
    box-sizing: border-box;
}

header.public-header .header-end {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    align-items: center;
}

header.public-header .header-end a {
    color: unset;
    text-decoration: none;
    padding: 0.5rem 0.75rem;
}
header.public-header .header-end a:hover {
    background: #f4f4f4;
    border-radius: 0.375rem;
}

header.public-header .header-end > * {
    margin-right: 10px;
}

header.public-header .p-menubar-button > i {
    color: #242e38 !important;
}

header.public-header .p-menubar-button:hover {
    background: unset !important;
    border: 1px solid #242e38;
}

@media only screen and (max-width: 960px) {
    header.public-header .header-end {
        display: none !important;
    }

    header.public-header .p-menubar-button {
        margin: unset !important;
        margin-left: auto !important;
        margin-right: 10px !important;
    }

    header.public-header .p-menubar-end {
        display: none !important;
    }

    header.public-header .p-menubar-root-list,
    #root header.public-header .p-menubar-root-list .p-menuitem a:hover {
        background: white !important;
    }

    #root header.public-header .p-menubar-root-list .p-menuitem .p-menuitem-text {
        color: #181818 !important;
    }
}
